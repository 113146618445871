exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-user-courses-aws-certified-cloud-practitioner-index-tsx": () => import("./../../../src/pages/user-courses/aws-certified-cloud-practitioner/index.tsx" /* webpackChunkName: "component---src-pages-user-courses-aws-certified-cloud-practitioner-index-tsx" */),
  "component---src-pages-user-courses-aws-certified-cloud-practitioner-study-session-complete-tsx": () => import("./../../../src/pages/user-courses/aws-certified-cloud-practitioner/study-session/complete.tsx" /* webpackChunkName: "component---src-pages-user-courses-aws-certified-cloud-practitioner-study-session-complete-tsx" */),
  "component---src-pages-user-courses-aws-certified-cloud-practitioner-study-session-index-tsx": () => import("./../../../src/pages/user-courses/aws-certified-cloud-practitioner/study-session/index.tsx" /* webpackChunkName: "component---src-pages-user-courses-aws-certified-cloud-practitioner-study-session-index-tsx" */),
  "component---src-pages-user-courses-index-tsx": () => import("./../../../src/pages/user-courses/index.tsx" /* webpackChunkName: "component---src-pages-user-courses-index-tsx" */)
}

